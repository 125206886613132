import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"test2"} />
		<Helmet>
			<title>
				Quarkly export
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
		</Helmet>
		<Components.StrapiProvider collection="my-collection">
			<Components.StrapiField fieldType="richText" fields="myRichTextJson" id="2" />
			<Components.StrapiField id="2" fields="mytextfield" fieldType="text" />
			<Components.StrapiField id="1" fields="mytextfield" fieldType="text" />
		</Components.StrapiProvider>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"665a226e1d712a0023345fdf"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});